import React, { useEffect } from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import { getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout"
import FeaturesComponent from "../components/features"

const IndexPage = () => {
  useEffect(() => {
    let revealEffects = document.createElement("script")
    revealEffects.src = "/js/_1_reveal-effects.js" // 👈 make sure to use the correct path
    revealEffects.id = "reveal-effects"
    document.body.appendChild(revealEffects)

    return () => {
      document.body.removeChild(revealEffects)
    }
  }, [])
  return (
    <Layout>
      <StaticQuery
        query={graphql`
          {
            strapiContactPage {
              title
              slug
            }
            strapiCourseInBrief {
              title
              slug
            }
            strapiHomepage {
              id
              Button
              Button2
              Description
              Title
              HeroImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      quality: 80
                      breakpoints: [768, 1024, 1280]
                    )
                  }
                }
              }
              vimeoVideo
              testimonial {
                quote
                author
              }
              subHeroHomepage {
                title
                description
                button
                subHeroHomepageImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        quality: 80
                        breakpoints: [768, 1024, 1280]
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
                stamp {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        breakpoints: [768, 1024, 1280]
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
              features {
                featureBlock {
                  title
                  color
                  link
                  featureImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          width: 768
                          height: 768
                          quality: 50
                          breakpoints: [768]
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                }
              }
              brochureCTA {
                title
                description
                button
                file {
                  localFile {
                    publicURL
                  }
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 192
                        quality: 50
                        breakpoints: [768]
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <div
              id="hero-main"
              className="container max-width-adaptive-lg margin-y-sm"
            >
              <section
                className="hero"
                style={{
                  backgroundImage: `url(${getSrc(
                    data.strapiHomepage.HeroImage.localFile.childImageSharp
                      .gatsbyImageData
                  )})`,
                }}
              >
                <div className="hero-clip padding-right-xxl@lg bg-cover bg-center text-center padding-y-xl color-white bg-contrast-higher bg-opacity-60%">
                  <div className="container max-width-adaptive-lg text-component margin-bottom-sm">
                    <h1 className="color-white reveal-fx reveal-fx--text-mask">
                      {data.strapiHomepage.Title}
                    </h1>
                    <p
                      style={{ whiteSpace: "pre-wrap" }}
                      className="reveal-fx reveal-fx--text-mask"
                      data-reveal-fx-delay="100"
                    >
                      {data.strapiHomepage.Description}
                    </p>
                  </div>

                  <div className="flex flex-wrap items-center gap-sm justify-center">
                    <Link
                      className="btn btn--primary"
                      to={`/${data.strapiContactPage.slug}`}
                    >
                      {data.strapiContactPage.title}
                    </Link>
                    <a href="#testimonial" className="color-inherit">
                      See more
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div
              id="testimonial"
              className="container max-width-adaptive-xl margin-y-xl"
            >
              <div className="grid gap-md items-center">
                <div
                  className="col-6@md padding-x-md reveal-fx reveal-fx--translate-right"
                  data-reveal-fx-delay="300"
                >
                  <svg width="4em" height="4em" viewBox="0 0 24 24">
                    <g transform="translate(0, 0)">
                      <path
                        fill="var(--color-contrast-low)"
                        d="M9,23H1c-0.55225,0-1-0.44727-1-1V12c0-5.04395,1.89014-8.38281,5.61768-9.92432 C6.12793,1.86572,6.7124,2.10742,6.92432,2.61768c0.21094,0.51074-0.03174,1.09521-0.54199,1.30664 C3.67383,5.04395,2.24365,7.36328,2.02881,11H9c0.55225,0,1,0.44775,1,1v10C10,22.55273,9.55225,23,9,23z"
                      ></path>
                      <path
                        fill="var(--color-contrast-low)"
                        d="M23,23h-8c-0.55225,0-1-0.44727-1-1V12c0-5.04395,1.89014-8.38281,5.61768-9.92432 c0.50879-0.20996,1.09473,0.03174,1.30664,0.54199c0.21094,0.51074-0.03174,1.09521-0.54199,1.30664 C17.67383,5.04395,16.24365,7.36328,16.02881,11H23c0.55225,0,1,0.44775,1,1v10C24,22.55273,23.55225,23,23,23z"
                      ></path>
                    </g>
                  </svg>

                  <div>
                    <p className="text-xxxl font-tertiary line-height-xs">
                      {data.strapiHomepage.testimonial.quote}
                    </p>
                    <p className="text-xl font-tertiary">
                      {data.strapiHomepage.testimonial.author}
                    </p>
                  </div>
                </div>

                <div className="col-6@md">
                  <div className="aspect-ratio-16:9">
                    {/* <iframe
                      title="VacStuds Intro"
                      loading="lazy"
                      src={
                        "https://player.vimeo.com/video/" +
                        data.strapiHomepage.vimeoVideo +
                        "?title=0&amp;byline=0&amp;portrait=0&amp;badge=0"
                      }
                      frameBorder="0"
                      allowFullScreen
                    ></iframe> */}
                    <video
                      poster="https://i.vimeocdn.com/video/974253374-dc9ce8250d4522e952404a270521a72db4a3b6e77ee060b84d717a2fe12298a8-d?mw=800"
                      controls
                    >
                      <source
                        type="video/mp4"
                        src="https://player.vimeo.com/external/190865459.hd.mp4?s=ed01f74cbf4cd05cfc0176018ea1c1df03df60a0&profile_id=169"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="hero-school"
              className="container max-width-adaptive-lg margin-y-sm"
            >
              <section
                className="hero"
                style={{
                  backgroundImage: `url(${getSrc(
                    data.strapiHomepage.subHeroHomepage.subHeroHomepageImage
                      .localFile.childImageSharp.gatsbyImageData
                  )})`,
                }}
              >
                {" "}
                <div className="hero-clip--right padding-left-xxl@lg bg-cover bg-center text-center padding-top-xl color-white bg-contrast-higher bg-opacity-60%">
                  <div className="container max-width-adaptive-lg text-component margin-bottom-sm">
                    <h1 className="color-white reveal-fx reveal-fx--text-mask">
                      {data.strapiHomepage.subHeroHomepage.title}
                    </h1>
                    <p
                      style={{ whiteSpace: "pre-wrap" }}
                      className="reveal-fx reveal-fx--text-mask"
                      data-reveal-fx-delay="100"
                    >
                      {data.strapiHomepage.subHeroHomepage.description}
                    </p>
                  </div>

                  <div className="flex flex-wrap items-center gap-sm justify-center">
                    <Link
                      className="btn btn--primary"
                      to={`/${data.strapiCourseInBrief.slug}`}
                    >
                      {data.strapiHomepage.subHeroHomepage.button}
                    </Link>
                  </div>

                  <div className="flex items-center justify-end padding-right-sm padding-bottom-sm">
                    <GatsbyImage
                      className="reveal-fx"
                      alt="Vacational Studies Stamp"
                      style={{ width: "6em" }}
                      image={
                        data.strapiHomepage.subHeroHomepage.stamp.localFile
                          .childImageSharp.gatsbyImageData
                      }
                    />
                  </div>
                </div>
              </section>
            </div>
            <FeaturesComponent
              features={data.strapiHomepage.features.featureBlock}
            />
            <section
              id="cta-banner"
              className="bg-contrast-low container max-width-adaptive-lg margin-bottom-sm overflow-hidden padding-component"
            >
              <div className="grid gap-md items-center">
                <div className="col-8">
                  <div className="padding-left-md@lg">
                    <div className="text-component">
                      <h2>{data.strapiHomepage.brochureCTA.title}</h2>
                      <p>{data.strapiHomepage.brochureCTA.description}</p>
                    </div>

                    <div className="margin-top-sm">
                      <div className="flex flex-wrap gap-sm items-center">
                        <a
                          href={
                            data.strapiHomepage.brochureCTA.file.localFile
                              .publicURL
                          }
                          className="btn btn--primary"
                        >
                          {data.strapiHomepage.brochureCTA.button}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <figure className="width-100% reveal-fx reveal-fx--rotate-left">
                    <GatsbyImage
                      image={{
                        ...data.strapiHomepage.brochureCTA.image.localFile
                          .childImageSharp.gatsbyImageData,
                      }}
                      className="block width-50%@lg"
                      alt="Vacational Studies Brochure"
                    />
                  </figure>
                </div>
              </div>
            </section>
          </>
        )}
      />
    </Layout>
  )
}

export default IndexPage
