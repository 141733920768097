import React from "react"
import { Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"

const FeatureBlock = ({ feature }) => {
  return (
    <Link to={`/${feature.link}`} className="card-v2 card-v2--blur-fx col-3@md">
      <GatsbyImage
        image={{
          ...feature.featureImage.localFile.childImageSharp.gatsbyImageData,
          aspectRatio: 1 / 1,
        }}
        alt={feature.title}
      />
      <figcaption
        className={
          "card-v2__caption card-v2__caption--" +
          feature.color +
          " padding-sm text-center"
        }
      >
        <div className="text-md text-base@md">{feature.title}</div>
      </figcaption>
    </Link>
  )
}

export default FeatureBlock
