import React from "react"
import Feature from "./feature"

const Features = ({ features }) => {
  return (
    <div
      id="features"
      className="container max-width-adaptive-lg margin-bottom-sm"
    >
      <div className="grid gap-sm">
        {features.map((feature, i) => {
          return <Feature feature={feature} key={`${feature.title}`} />
        })}
      </div>
    </div>
  )
}

export default Features
